<template>
  <div style="height: inherit">

    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="Object.keys(current_chat).length === 0"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
        >
          Selecciona un chat para empezar
        </h4>
      </div>
      <div
        v-else
        class="active-chat"
      >
        <active-chat 
          :key="chat_changed" 
          :chat="chat_active" 
          :inner_loader="inner_loader"
          :proposals_user="proposals_user"
          @update-chat="updateChat" 
          v-if="loaded_detail_chat"
        />
      </div>
    </section>

    <portal to="content-renderer-sidebar-left">
      <chat-left-side 
        :chats="all_chats" 
        @open-chat="openChat" 
        v-if="loaded" 
        @filter_chats="filterChats"
        :value_drop="value_drop"
        :key="update_chats"
      />
    </portal>
  </div>
</template>

<script>
import chatLeftSide from '../chat/leftSideBar.vue'
import chat_service from '@/services/chat'
import proposals_services from '@/services/proposals';
import utils from '@/libs/utils';
import activeChat from './activeChat.vue';

export default {
  name: 'dashboardChat',
  components: {
    // BSpinner,
    chatLeftSide,
    activeChat,
  },
  data() {
    return {
      chat_changed: false,
      chat_active: {},
      current_profile: {},
      all_chats: [],
      current_chat: {},
      proposal: {},
      loaded: false,
      loaded_detail_chat: false,
      value_drop: 'all_conversations',
      update_chats: false,
      interval_loading: null,
      inner_loader: false,
      proposals_user: [],
    }
  },
  created() {
    this.showLoader();
    this.getChats({});
    if (this.$route.params.chat_uuid) this.openChat(this.$route.params.chat_uuid);
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
    this.$root.$on('update_chat_notification', (chat_uuid) => {
      this.updateChat(chat_uuid);
    });
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'static' });
  },
  methods: {
    showLoader() {
      this.$vs.loading({
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.inner_loader = false;
      this.$vs.loading.close()
    },
    getProposalsByUser(user_id) {
      proposals_services.getProposalsByUser(user_id).then((response) => {
        this.proposals_user = response.results;
      })
    },
    getChats(data) {
      chat_service.getChats(data).then((response) => {
        this.all_chats = [];
        this.all_chats = this.formatChats(response.results);
        this.loaded = true;
        this.hideLoader();
        this.update_chats = !this.update_chats;
      });
    },
    updateChat(chat_uuid) {
      this.showLoader()
      chat_service.getDetailChat(chat_uuid).then(response => {
        this.chat_active = response
        this.chat_changed = !this.chat_changed
        this.hideLoader()
      })

      chat_service.markAsReaded(chat_uuid).then(() => {
      })
    },
    openChat(chat_id) {
      if (chat_id !== undefined) {
        window.history.pushState({}, null, `/proposal/chat/${chat_id}`);
        this.inner_loader = true;
        chat_service.getDetailChat(chat_id).then(response => {
          this.loaded_detail_chat = true;
          if (response.status >= 400) this.$router.push({name: 'dashboard-analytics'});
          this.chat_active = response
          this.chat_changed = !this.chat_changed
          this.hideLoader()
          this.getProposalsByUser(response.creator.id);
        })

        chat_service.markAsReaded(chat_id).then(() => {
        })

        // TODO: Servicio de marcar como leidos los mensajes
        this.current_chat = this.all_chats.find(chat => chat.id === chat_id) === undefined ? {} : this.all_chats.find(chat => chat.id === chat_id);
        if (Object.keys(this.current_chat).length > 0) this.current_chat.unseenMsgs = 0;
      }
    },
    filterChats(by_value) {
      if ('proposals_chat' === by_value) this.getChats({only_sales: false})
      else if ('sales_chats' === by_value) this.getChats({only_sales: true});
      else this.getChats({})
      this.current_chat = {};
      this.value_drop = by_value;
      this.$route.params.chat_uuid = undefined;
    },
    formatChats(chats) {
      const user = utils.getUserData()
      const results = []
      chats.forEach(chat => {
        const obj = {
          id: chat.uuid,
          user: chat.creator.id === user.id ? chat.brand : chat.creator,
          lastMessage: chat.last_message,
          unseenMsgs: chat.unread_messages,
          ...chat
        }
        results.unshift(obj);
      });

      return results
    },
  },
} 
</script>
<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
<style>
.sidebar-chats-responsive .b-sidebar{
  width: 280px !important;
}
@media(max-width: 600px) {
  .content-area-wrapper {
    overflow-x: scroll !important; 
  }
}
</style>
