<template>
  <div class="row m-0">
    <div :class="`${data_header.is_avaible_to_editing && !data_header.is_in_sidebar ? 'col-12 col-md-8' : 'col-12'} p-0`">
      <!-- ORIGINAL LAYOUT -->
      <div class="grid">
        <div :class="`box-image-1 position-relative ${is_preview ? 'cursor-pointer' : ''}`" :style="getBackgroundColor()" @click="is_preview ? open_modal_edit_color = true : ''">
          <span :class="`${is_sidebar ? '' : 'main-title-font-size'} main-title-hover-image`">{{data_header.title}}</span>
          <feather-icon icon="EditIcon" class="edit-icon-color" v-if="is_preview"></feather-icon>
  
          <div class="background-button-edit" @click="$emit('edit_campaign', 'title')" v-if="data_header.is_avaible_to_editing && !data_header.is_in_sidebar">
            <h5 class="text-white animation-opacity-words">{{$t('creator.editTitleSingle')}}</h5>
          </div>
        </div>
        <div class="box-image-2 position-relative">
          <b-avatar :src="data_header.cover_image" square class="avatar-preview-header-steps"></b-avatar>
  
          <div class="background-button-edit" @click="$emit('edit_campaign', 'cover')" v-if="data_header.is_avaible_to_editing && !data_header.is_in_sidebar">
            <h5 class="text-white animation-opacity-words">{{$t('creator.editCoverImage')}}</h5>
          </div>
        </div>
      </div>
      <!-- --------------- -->
      <b-modal v-model="open_modal_edit_color" title="Edit color casting call" @ok="updateColor">
        <b-form-group label="Select a color">
          <b-form-input type="color" v-model="color_modal"></b-form-input>
        </b-form-group>
      </b-modal>
    </div>

    
    <b-col class="d-none d-md-block col-4 p-0 pl-2" v-if="data_header.is_avaible_to_editing && !data_header.is_in_sidebar">
      <div class="bubble-edit top-0 mb-1" @click="$emit('edit_campaign', 'title')">
        <strong class="avenir-bold mb-1 d-block"><feather-icon icon="Edit2Icon" class="mr-05"></feather-icon> {{$t('creator.titleStep')}}</strong>
        <p class="avenir-medium m-0">{{ $t('creator.editTitleText') }}</p>
      </div>

      <div class="bubble-edit" @click="$emit('edit_campaign', 'cover')">
        <feather-icon icon="Edit2Icon"></feather-icon>
        <strong class="ml-05 avenir-bold">{{$t('creator.coverImg')}}</strong>
      </div>        
    </b-col>
  </div>
</template>

<script>
import {
  BAvatar,
  BModal,
  BFormInput,
  BFormGroup,
  BCol,
} from 'bootstrap-vue';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'headerPreviewCasting',
  components: {
    BAvatar,
    BModal,
    BFormInput,
    BFormGroup,
    BCol,
  },
  data() {
    return {
      open_modal_edit_color: false,
      color_modal: this.color,
      is_preview: this.$route.fullPath.includes('preview'),
    }
  },
  props: {
    color: {
      type: String,
    },  
    data_header: {
      type: Object
    },
    is_sidebar: {
      type: Boolean,
    }
  },
  methods: {
    updateColor() {
      const form_data = new FormData();
      form_data.append('main_color', this.color_modal);
      casting_calls_services.updateCastingCall(this.data_header.uuid, form_data).then((response) => {
        this.$emit('update_color', response.response.main_color)
      });
    },
    getBackgroundColor() {
      return `background: linear-gradient(90deg, rgba(2,0,36,1) 0%, ${this.color} 100%);`
    },
  }
}
</script>
<style scoped lang="scss">
.main-title-hover-image {
  position: absolute;
  margin: 0;
  bottom: 0.5em;
  left: 0.5em;
  color: white;
  z-index: 1;
  text-align: left;
  word-wrap: break-word;
}
.grid {
  gap: 7px;
  display: flex;
  position: relative;
  max-height: 310px !important;
  min-height: 150px;
}
.box-image-1 {
  width: 55%;
}
.box-image-1:hover .edit-icon-color {
  display: block;
}
.box-image-2 {
  width: 45%;
}
.avatar-preview-header-steps {
  width: 100%;
  height: 100%;
  max-height: 380px;
  object-fit: cover;
}
.edit-icon-color {
  position: absolute;
  top: 1em;
  right: 1em;
  height: 20px;
  width: 20px;
  display: none;
  color: white;
}
@media(max-width: 3000px) {
  .main-title-font-size {
    font-size: 2vw;
  }
}
.ml-05 {
  margin-left: 0.5em;
}
.mr-05 {
  margin-right: 0.5em;
}
.animation-opacity-words {
  opacity: 0;
}

.background-button-edit {
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 0.55em;
  z-index: 10;
}

.box-image-2:hover .background-button-edit,
.box-image-1:hover .background-button-edit
{
  background-color: #3b8da888;
  transition: all 500ms;
  cursor: pointer;
  backdrop-filter: blur(10px);
}
.box-image-2:hover .animation-opacity-words,
.box-image-1:hover .animation-opacity-words
{
  opacity: 1;
  transition: all 500ms;
}


.box-image-2:not(:hover) .background-button-edit,
.box-image-2:not(:hover) .animation-opacity-words,
.box-image-1:not(:hover) .background-button-edit,
.box-image-1:not(:hover) .animation-opacity-words

{
  transition: all 500ms;
}

.bubble-edit {
  text-align: left;
  padding: 1em;
  color: #3b8DA8;
  background: #E6f2f6;
  border-radius: 1em;
  cursor: pointer;
  position: sticky;
  position: -webkit-sticky;
  top: 12em;

  &:hover {
    transition: all 300ms;
    background: #3b8da82f;

    &:before {
      border: 10px solid #3b8da82f;
      content: '';
      border-left-color: transparent;
      border-bottom-color: transparent;
      border-right-color: transparent;
      position: absolute;
      transform: rotate(90deg);
      left: -20px;
      transition: all 300ms;
    }
  }

  &:before {
    border: 10px solid #E6f2f6;
    content: '';
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    position: absolute;
    transform: rotate(90deg);
    left: -20px;
  }
}
.top-0 {
  top: 0;
}
</style>
