<template>
  <div class="loader-video-brandme" >
    <video :src="require('@/assets/videos/loader_brandme_ia.mp4')" :autoplay="true" :loop="true" ref="video"></video>
  </div>
</template>

<script>

export default {
  title: 'LoaderVideoBrandme',
  props: {
    loaded: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      show: this.loaded,
      video_src: require('@/assets/videos/loader_brandme_ia.mp4'),
    }
  },
  mounted() {
    this.$refs.video.play();
  },
  methods: {
    onVideoLoaded() {
      this.$refs.video.play();
    },
  }
};
</script>

<style lang="scss">
.loader-video-brandme {
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: progress;

  video {
    width: 60%;
    height: 60%;
  }
}
</style>